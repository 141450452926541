export const locale = {

    lang: 'AZAZ',
  
    data: {
  
        loginButton: "Daxil ol",
        userName: "İstifadəçi adı",
        pass: "Şifrə",
        remember: "Məni yadda saxla",
        forgotPass: "Şifrəni unutmusan?",
        modules: "Modullar",
        generic: "Ümumi",
        search: "Axtar",
        logout: "Çıxış",
        waitingJob: "Gözləyən işlərim",
        myStarted: "Mən başladım",
        myIncluded: "Mən daxil oldum",
        myCompleted: "Tamamlanan işlər",
        jobList: "İş Siyahısı",
        formNo: "Forma No",
        status: "Status",
        starter: "Başlayan",
        generateLink: "keçid yaratmaq",
        fileLink: "Fayl Linki",
        givePasswordToTheLink: "linkə parol verin",
        giveTheLinkAnExpirationDate: "Linkə son istifadə tarixini verin",

        assigned: "Təyin olunmuş",
        timeoutDate: "Vaxt bitmə tarixi",
        startDate: "Başlama tarixi",
        processing: "İşlənir",
        you: "siz",
        history: "Tarixçə",
        description: "Təsvir",
        date: "Tarix",
        growShrink: "Genişlət / Kiçilt",
        close: "Bağla",
        gridReset: "Küçültmə qaydasını sıfırla",
        columnSelect: "Sütun seçimi",
        jobName: "İşin adı",
        keyword: "Açar söz",
        completion: "Tamamlanma",
        all: "Hamısı",
        completedDate: "Son tarix",
        completions: "Tamamlanmış",
        ongoing: "Davam edir",
        formName: "Forma adı",
        sunday: "Bazar",
        monday: "Bazar ertəsi",
        tuesday: "Çərşənbə axşamı",
        wednesday: "Çərşənbə",
        thursday: "Cümə axşamı",
        friday: "Cümə",
        saturday: "Şənbə",
        pdfExport: "Pdf-ə ixrac et",
        sendPassword: "Şifrəmi göndər",
        assignedDate: "Tapşırma tarixi",
        events: "Tədbirlər",
        jobListMessageArea: "iş siyahısı mesaj sahəsi",
  
        new: "Yeni",
        save: "Saxla",
        formula: "Formula",
        selectDataSource: "Veri Mənbəyi seçin",
        requiredField: "Tələb olunan sahə",
        suffix: "Suffiks",
        valueColumn: "Qiymət Sütunu",
        showLength: "Sütunlarda tapşırıqların sayını göstər",
        clearButton: "Təmizlə Düyməsi",
        allCaps: "Bütün hərflər böyük",
        autoAsc: "Avto Artan",
        width: "Genişlik",
        length: "Uzunluq",
        functions: "Funksiyalar",
        selectElement: "Elementi seçin",
        selectColumn: "Sütunu seçin",
        edit: "Düzəliş et",
        changeEventActions: "Tədbir Tədbirlərini dəyişdirin",
        barcode: "Barkod",
        systemInfo: "Sistem Məlumatı",
        prefix: "Prefiks",
        max: "Maksimum",
        showMinMaxInfo: "Minimum və maksimum məlumatı göstər",
        selectUser: "İstifadəçini seç",
        spinButton: "Döndürmə düyməsi",
        numberIncDecButton: "Rəqəm artırma-azaltma düyməsi",
        decimal: "Onluq rəqəm",
        numeral: "Rəqəm",
        wrongFormat: "Səhv format daxil etdiniz",
        height: "Hündürlük",
        microphone: "Mikrofon",
        notifyDataReset: "Məlumat Sıfırlanacaq",
        data: "Məlumat",
        dynamicData: "Dinamik Məlumat",
        manualData: "Əl ilə məlumat",
        selectDataGrid: "Veri cədvəli seçin",
        overrideUserChange: "İstifadəçi dəyişikliklərini üstün tut",
        textColumn: "Mətn Sütunu",
        makeInitialValue: "Əsas İlk Dəyər",
        undo: "Geri al",
        copy: "Kopyala",
        preview: "Əvvəlki görünüş",
        delete: "Sil",
        cancel: "Ləğv et",
        languageSettings: "Dil Ayarları",
        messageSqlLinkDeleted: "Verilənləri əl ilə əlavə etdiyiniz üçün sql skripti və link ünvanı silinəcək! Davam edilsin?",
        messageSqlLinkDeletedForMenu: "Verini menyü kimi seçdiyiniz üçün SQL script faylı və link ünvanı silinəcək! Davam etmək istəyirsiniz?",
        permissions: "İcazələr",
        
  
        objectid: "Obekt ID",
        fontSize: "Mətn Ölçüsü",
        fontWidth: "Mətn Eni",
        fontStyle: "Mətn Stili",
        underline: "Altınıxət",
        textColor: "Mətn Rəngi",
        visibility: "Görünürlük",
        textAlignment: "Mətn Yerləşdirilməsi",
        settings: "Ayarlar",
        messageUnsaved: "Dəyişikliklərinizi saxlamadan çıxmağınıza əminsiniz?",
        warning: "Xəbərdarlıq!",
        userAuthorizations: "İstifadəçi İcazələri",
        formPreferences: "Form Tərcihləri",
        formOpeningevent: "Form Açma Tədbiri",
        chooseConnectionAddress: "Bağlantı Ünvanını Seçin",
        selectPriorty: "Prioritet Seçin",
        loadingEditor: "Editor Yüklənir...",
        showAddButton: "Sütun Əlavə Düyməsini Göstərin",
        update: "Yenilə",
        connectionID: "Bağlantı ID",
        formPreview: "Form Önizlemesi",
        nameDisplayedform: "Göstərilən formun adı",
        formNamevariable: "Form Adı Dəyişəni",
        category: "Kateqoriya",
        formColor: "Formun Rəngi",
        generalForm: "Ümumi Forma",
        saveForm: "Formu Saxla",
        messageNeworupdate: "Mövcud formda etdiyiniz dəyişikliklər silinəcək. Davam edilsin?",
        messageDelete: "Formanı silməyə əminsiniz?",
        toastDeleted: "Forma Silindi",
        toastError: "Üzgünük, işləmə zamanı bir səhv baş verdi",
        alertDifferentID: "Bu id başqa bir yerlərdə istifadə olunur, xahiş edirik fərqli bir id təyin edin.",
        confirmChanges: "Dəyişiklikləri təsdiqləyin",
        toastUserpermission: "İstifadəçinin form dizaynı üçün icazəsi yoxdur.",
        
  
        toastCodeeditor: "Kod redaktoru artıq mövcuddur",
        messageDeletesure: "Bu komponentdəki elementlər silinəcək",
        messageSure: "Əminsiniz?",
        successful: "Uğurlu",
        error: "Səhv",
        toastFormupdate: "Forma yeniləndi",
        toastMistake: "Üzr istəyirik, işləmə zamanı bir səhv baş verdi",
        toastSaved: "Saxlanıldı",
        toastCopied: "Kopyalandı",
        toastEmptyFormMessage: "Hələ ki, heç bir form elementi əlavə edilməyib",
        toastGiveName: "Zəhmət olmasa formanıza ad verin",
        toastGiveVariableName: "Zəhmət olmasa form adı dəyişəninə ad verin",
        toastVariableNameNumberWarning: "Dəyişən adı rəqəm ilə başlaya bilməz",
        formCopy: "Formanı Kopyalayın",
        messageButtonDelete: "Düyməni silmək istədiyinizə əminsiniz?",
        noDataFound: "Məlumat tapılmadı",
        language: "Dil",
        filterDatagrid: "Datagrid Filtrasiya",
        addManualData: "Runtime zamanı əlavə manual məlumat",
        dateFormat: "Tarix Formatı",
        messageSqlLinkDelete: "Çünki məlumatları əl ilə əlavə etdiniz, sql skripti və link ünvanı silinəcək! Davam edilsin?",
        timeOption: "Saat seçimi",
        defaultTodaysDate: "Defolt bu günün tarixini seçin",
        dateRange: "Tarix Aralığı",
        minToday: "Minimum Bu Gün",
        maxToday: "Maksimum Bu Gün",
        dateRangeDay: "Tarix Aralığı (Gün)",
        enterDateRangeValue: "Tarix aralığı dəyərini daxil edin",
        imageIdColumn: "ID Sütunu",
        statusSettings: "Status Ayarları",
        active: "Aktiv",
        passive: "Passiv",
        enable: "Aktivləşdir",
        disable: "Deaktivləşdir",
        visible: "Görünür",
        invisible: "Görünmür",
        items: "Əşyalar",
        showItems: "Əşyaları göstər",
        array: "massiv",
        letLabelAppear: "Başlığın görünməsini təmin edin",
        add: "Əlavə et",
        horizontal: "Üfüqi",
        vertical: "Şaquli",
        text: "Mətn",
        alertFillRequiredFields: "Xahiş olunur tələb olunan sahələri doldurun",
        alertSignatureField: "İmza sahəsi boş ola bilməz",
        messageApproveTransaction: "Əməliyyatı təsdiqləyirsiniz?",
        button: "Düymə",
        reasonforRefusal: "İmtina Səbəbi",
        enterReasonRejection: "İmtina Səbəbini daxil edin",
        reject: "İmtina et",
        addDeleteButton: "Düymə əlavə et / sil",
        showBorder: "Çərçivə Görünsün",
        buttons: "Düymələr",
        clean: "təmizlə",
        buttonSettings: "Düymə Ayarları",
        saveLocation: "Yerləşdirilmə yeri",
        paymentButton: "Ödəniş Düyməsi",
        paymentProvider: "Ödəniş Təchizatçısı",
        price: "Qiymət",
        numberOfInstallments: "Taksit Sayı",
        descriptionField: "Təsvir Sahəsi",
        current: "Cari",
        currentForm: "Cari Forma",
        name: "Ad",
        surname: "Soyad",
        country: "Ölkə",
        city: "Şəhər",
        address: "Ünvan",
        authorizations: "İcazələr",
        users: "İstifadəçilər",
        user: "İstifadəçi",
        givePermission: "İcazə ver",
        groups: "Qruplar",
        group: "Qrup",
        saveandSend: "Yadda Saxla və Göndər",
        approve: "Təsdiqlə",
        sendBack: "Geri göndər",
        
  
        deslineAndEnd: "İmtina və Son",
        complete: "Tamamlandı",
        blue: "Mavi",
        gray: "Boz",
        green: "Yaşıl",
        red: "Qırmızı",
        left: "Sol",
        right: "Sağ",
        color: "Rəng",
        symbol: "Sembol",
        position: "Vəziyyət",
        action: "Əməliyyat",
        total: "Cəmi",
        filePath: "Fayl Yolu",
        fileName: "Fayl Adı",
        fileUpload: "Fayl Yüklə",
        choose: "Seçin",
        updateData: "Məlumatları Yenilə",
        show: "Göstər",
        columns: "Sütunlar",
        changeEvent: "Tədbir Dəyişdir",
        columnSettings: "Sütun Ayarları",
        columnName: "Sütun Adı",
        deleteColumn: "Sütunu Sil",
        columnSqlScripts: "Sütun SQL Skriptləri",
        imageNameColumn: "Fayl Adı Sütunu",
        columnLanguageSetting: "Sütun Dil Ayarı",
        dataSource: "Mənbə",
        filter: "Filtr",
        reset: "Sıfırla",
        column: "Sütun",
        image: "Şəkil",
        searchLocation: "Yer Axtarışı...",
        draggable: "Sürüklənəbilir",
        usersCanChange: "İstifadəçilər Dəyişə Bilər",
        multiMarker: "Çoxlu Markır",
        markerLimit: "Markır Limiti (0: Limit Yoxdur)",
        meetingType: "Tədbir Növü",
        meetingName: "Tədbir Adı",
        loadingFiles: "Fayllar yüklənir...",
        fileSizeExceeded: "Fayl ölçüsü aşılıb",
        filesLoaded: "Fayllar yükləndi",
        condition: "Şərt",
        value: "Dəyər",
        connectionAddress: "Bağlantı Ünvanı",
        imageTypeColumn: "Fayl Növü sütunu",
        visibleColumn: "Görünən Sütun",
        selectionMode: "Seçim Rejimi",
        selectMode: "Rejim Seçin",
        datagridFiltering: "Datagrid Filtrləmə",
        imageDataColumn: "Şəkil Sütunu",
        selectDataColumn: "Datagrid Sütunu Seçin",
        dataFormat: "Məlumat Formatı",
        photoPreview: "Şəkil Önizləməsi",
        videoPreview: "Video Önizləməsi",
        displayPhoto: "Şəkil Göstər",
        displayVideo: "Video Göstər",
        imageColumn: "Şəkil Sütunu",
        videoColumn: "Video Sütunu",
        columnNameAppear: "Görünməli Sütun Adı",
        selected: "Seçilmiş",
        inStock: "Stokda",
        messageSave: "Dəyişiklikləri yadda saxlamağa əminsiniz?",
        gallery: "Qalereya",
        dollar: "dollar",
        sterling: "sterling",
        underPhoto: "Şəkil Altında",
        topPhoto: "Şəkil Üstündə",
        byCategory: "Kateqoriyaya görə",
        dashboardShowHide: "İdarə panelini Göstər/Gizlət",
        showDetail: "Detalı Göstər",
        
        hideDetail: "Ətraflı Gizlət",
        decrease: "Azalt",
        increase: "Artır",
        watch: "İzlə",
        watchMovie: "Film İzlə",
        noRecordsFound: "Heç bir qeyd tapılmadı!",
        appearance: "Görünüş",
        numberofColumns: "Sütunların sayı",
        pagingRecords: "Sayfalaşdırma Qeydləri",
        detailLocation: "Ətraflı Məkanı",
        hideImages: "Şəkilləri Gizlət",
        quantityCategoryDisable: "Miqdar Artdıqda Kateqoriyanı Dayandır",
        general: "ümumi",
        idColumn: "Kimlik Sütunu",
        availableQuantity: "Mövcud Miqdar",
        categoryColumn: "Kateqoriya Sütunu",
        photoColumn: "Şəkil Sütunu",
        titleColumn: "Başlıq Sütunu",
        priceColumn: "Qiymət Sütunu",
        updatablePrice: "Yenilənəbilir Qiymət",
        videoGallery: "Video Qalereya",
        uniqueComponent: "Unikal Komponent",
        showVariant: "Varyantı Göstər",
        transferTotalAmount: "Ümumi Məbləği Köçür",
        title: "Başlıq",
        hide: "Gizlə",
        detail: "Ətraflı",
        priceQuery: "Qiymət Sorğusu",
        quantityQuery: "Miqdar Sorğusu",
        uniqueComponentError: "Unikal Komponent Səhvi",
        joinColumn: "Birləşdir Sütunu",
        nameColumn: "Ad Sütunu",
        variantScript: "Varyant Skripti",
        variantImages: "Varyant Şəkilləri",
        variantJoinColumn: "Varyant Birləşdir Sütunu",
        showAll: "Hamısını Göstər",
        showSelected: "Seçilmişləri Göstər",
        showByCategory: "Kateqoriyaya görə göstər",
        showInStock: "Stokdakıları Göstər",
        notYetAdded: "Hələ əlavə edilməyib",
        added: "Əlavə edildi",
        startingDate: "Başlanğıc Tarixi",
        endDate: "Son Tarix",
        themeFeatures: "Tema Xüsusiyyətləri",
        menu: "Menyu",
        selectMenu: "Menyu Seç",
        selectSubmenu: "Alt Menyu Seç",
        selectMenuBrowser: "Menyu Brauzerini Seç",
        nameElement: "Ad Elementi",
        subject: "Mövzu",
        picture: "şəkil",
        pictureElement: "Şəkil Elementi",
        startingDateElement: "Başlanğıc Tarixi Elementi",
        endDateElement: "Son Tarix Elementi",
        usersCanEdit: "İstifadəçilər düzəliş edə bilər",
        thisPropertyCant: "Bu xüsusiyyət SQL'dən götürülürsə, istifadə edilə bilməz",
        calendarDisplay: "Təqvim Göstərmə Xüsusiyyətləri",
        monthly: "Aylıq",
        generalDisplayLunarDays: "Ay Gününün Ümumi Görünüşü",
        agenda: "Gündəlik",
        listofWeeklyEvents: "Həftəlik Tədbir Siyahısı",
        weekly: "Həftəlik",
        weekend: "Həftəsonu",
        generalDisplayWeekDays: "Həftə Gününün Ümumi Görünüşü",
        showWeekendDaysWhileWeekdays: "Həftə gününü göstərirək, həftəsonu gününü göstər",
        daily: "Günlük",
        listofDailyEvents: "Günlük Tədbir Siyahısı",
        startTime: "Başlama Vaxtı",
        endTime: "Bitmə Vaxtı",
        changeDefaultColor: "Susmaya görə rəngləri dəyiş",
        weekdayColor: "Həftə gününün rəngi",
        weekendColor: "Həftəsonu gününün rəngi",
        eventColor: "Tədbirin rəngi",
        groupBySql: "SQL-ə əsasən qruplaşdır",
        groupColumnName: "Qruplaşdırma Sütunu Adı",
        assignedColumnName: "Təyin olunan Sütununun Adı",
        taskPriortiyColumnName: "Tapşırığın Prioritet Sütunu Adı",
        taskSubjectColumnName: "Tapşırığın Mövzu Sütunu Adı",
        taskDetailColumnName: "Tapşırığın Təfərrüat Sütunu Adı",
        taskQuery: "Tapşırıq Sorğusu",
        taskKeyExpression: "Tapşırığın Açar İfadəsi",
        taskTitleExpression: "Tapşırığın Başlıq İfadəsi",
        taskStartDateExpression: "Tapşırığın Başlanğıc Tarixi İfadəsi",
        taskEndDateName: "Tapşırığın Son Tarix Adı",
        taskParentExpression: "Tapşırığın Ana İfadəsi",
        taskProgressExpression: "Tapşırığın Gediş İfadəsi",
        taskColorExpression: "Tapşırığın Rəng İfadəsi",
        taskConnections: "Tapşırıq Əlaqələri",
        
        diagramClear: "Diyagramı təmizləmək istədiyinizə əminsinizmi? Bu əməliyyat geri alına bilməz.",
        uniqueComponentMenuCloneWarning: "Lütfən unikal komponenti kopyalamağdan əvvəl dəyişin.",
        filesLoading: "Fayllar yüklənir...",
        couldnotbeLoaded: "yüklənə bilməz.",
        fileSizeLimit: "Fayl Həcmi Məhdudiyyəti",
        filesUploaded: "Fayllar yükləndi",
        filesWillDeleted: "Fayllar silinəcək!",
        filesSureDelete: "Faylları silmək istədiyinizə əminmisiniz?",
        fileDelete: "Faylı silmək istəyirsiniz?",
        uploadedPhotos: "Yüklənmiş Şəkillər",
        maxSize: "Maksimum Həcm",
        
  
        center: "Orta",
        size: "Boyut",
        canItBeUpdated: "Yüklendikten sonra güncellenebilir mi?",
        uploadedFiles: "Yüklənmiş Fayllar",
        type: "Növ",
        showdeleteOldData: "Köhnə Məlumatların Silinməsi Üçün Variantlar",
        videoBrowser: "Sizin brauzer video etiketini dəstəkləmir.",
        deleted: "Silindi",
        theseComponentElementsDeleted: "Bu komponent elementləri silinəcək.",
        invalidTarget: "Səhv hədəf",
        ridge: "Düz Xətt",
        dotted: "Nöqtələr",
        dashed: "Sızdırılmış",
        addRowColumn: "Sətir/Sütun Əlavə Et",
        dataPagingShow: "Məlumatları səhifələrlə əldə edin",
        borderType: "Künc Növü",
        maxCharacters: "Maksimum simvol sayı",
        edgeThickness: "Kənar eni",
        lineSpacing: "Sətir Aralığı",
        settingsRow: "Sütun Sətir/Sütun Ayarları",
        addRow: "Sətir Əlavə Et",
        deleteRow: "Sətiri Sil",
        rowPermission: "Sətir İcazələri",
        addTabs: "Qalxımlar Əlavə Et",
        deletePanel: "Paneli Sil",
        tabPermission: "Qalxım İcazəsi",
        addPanel: "Panel Əlavə Et",
        panelPermission: "Panel İcazəsi",
        backgroundColor: "Arxa Plan Rəngi",
        saveAndCloseChildForm: "Yeni bir form açarkən bu alt formu saxla və bağla",
        icon: "Sümbol",
        selectForm: "Form Seçin",
        fontColor: "Yazı Rəngi",
        minCharacters: "Minimum simvol sayı",
        generalCategory: "Ümumi Kateqoriya",
        removeRow: "Qeydi silmək istədiyinizə əminsiniz?",
        send: "Göndər",
        declineFinish: "Rədd et və Bitir",
        finish: "Bitir",
        noData: "Məlumat yoxdur",
        createEditMenu: "Menyu Yaradın/Tənzimləyin",
        addNewBreakdown: "Yeni Qırılım Əlavə Et",
        addForm: "Form Əlavə Et",
        addBottomBreak: "Aşağı Qırılım Əlavə Et",
        addNode: "Node Əlavə Et",
        topCategory: "Üst Kateqoriya",
        explanation: "Təsvir",
        browserTitle: "Brauzer Başlığı",
        formTitle: "Form Başlığı",
        addSqlColumn: "SQL Sütunu Əlavə Et",
        formBg: "Form Arxa Planı",
        codeEditor: "Kod Redaktoru",
        authorityType: "Yetki Növü",
        viewOnly: "Yalnız Baxış",
        dark: "Tünd",
        white: "Ağ",
        hightBlack: "Qara",
        run: "İcra Et",
        rowColor: "Sətir Rəngi",
        operator: "Operator",
        flowOfForm: "Formun Axınını",
        columnWidth: "Sütun Eni",
        alignLeft: "Sol Tərəfə Hərfləndir",
        alignRight: "Sağ Tərəfə Hərfləndir",
        alignButton: "Düyməni Hərfləndir",
        imageTableNameColumn: "Cədvəl Adı",
        uploadBg: "Arxa Plan Yüklə",
        selectFile: "Fayl Seç",
        dropFile: "Faylı buraya sürükləyin",
        alwaysRoundUp: "Həmişə yuxarı yuvarlaqlaşdır",
        alwaysRoundBot: "Həmişə aşağı yuvarlaqlaşdır",
        default: "Varsayılan",
        deleteOldDataTable: "Cədvəlin köhnə məlumatlarını silmək istəyirsiniz?",
        deleteOldData: "Köhnə məlumatları silmək istəyirsiniz?",
        integer: "Tam Ədəd",
        double: "Onluq Ədəd",
        pleaseFillFields: "Xahiş olunur zəruri sahələri doldurun.",
        toastColumnNameNumber: "Sütun adı rəqəm ilə başlaya bilməz",
        areYouSure: "Əminsiniz?",
        columnDeleted: "Sütun silinəcək",
        allColumnDeleted: "Bütün Sütunlar Silinəcək",
        idCantStartNumber: "Id rəqəm ilə başlaya bilməz",
        uploadFile: "Fayl Yüklə",
        fileViewer: "Fayl Baxışı",
        ok: "Oldu",
        map: "Xəritə",
        editPanel: "Paneli redaktə edin",
        filterColumnName: "Filter Sütun Adı",
        hintExamples: "TotalMiqdar, nümunə:Maas",
        columnLanguageSettings: "Sütun Dil Ayarları",
        fileColumn: "Fayl Sütunu",
        fileNameColumn: "Fayl Adı Sütunu",
        extensionColumn: "Uzantı Sütunu",
        multipleSelection: "Çoxlu Seçim",
        addSelectionsRows: "Seçimləri sətir kimi əlavə edin",
        mapSettings: "Xəritə Ayarları",
        formatAndFeatures: "Format və Xüsusiyyətlər",
        enterDateRange: "Tarix aralığı dəyərini daxil edin",
        filesCanUploadedUsers: "İstifadəçilər fayllar yükləyə bilər",
        barcodeColumn: "Barkod Sütunu",
        autoAscend: "Avtomatik Artım",
        roundType: "Yuvarlaqlaşdırma Növü",
        font: "Font",
        columnProcess: "Sütun Əməliyyatı",
        exportColumnTotal: "Sütun ümumi miqdarı",
        outsideElementDisabled: "Xarici Elementləri Deaktiv Edin",
        chat: "Çat",
        languages: "Dillər",
        display: "Şəkil",
        enterYourComment: "Şərhinizi daxil edin",
        comment: "Şərhlər",
        fileUploaded: "şəkil yükləndi",
        pdfViewIsButton: "Düymə kimi göstər",
        takeScreenshot: "Ekran Görüntüsü Al",
        alertText: "Xəbərdarlıq Mətni",
        
        clearButtonVisible : "Təmizlə düyməsini göstər",
  
        allText :"Hamısı",
          
        letter : "Yalnız hərf",
          
        letterandnumber :"Hərf və rəqəm",
          
        letterandsymbol :"Hərf və simvol",
          
        numberandsymbol: "Rəqəm və simvol",
        showIDColumn : "ID Sütununu göstər",
          
        showCopyButton :"Kopyala düyməsini göstər",
          
        showDeleteButton : "Sil düyməsini göstər",
          
        showCheckboxButton : "Checkbox düyməsini göstər",
          
        autoRowHeight :"Avtomatik sətir hündürlüyü",
          
        exportImport : "Formanı idxal/idxal et",
          
        export : "İxrac et",
          
        import : "Idxal et",
          
        toastImport :"Forma idxalı tamamlandı.",
          
        warningApproveImport : "Tənzimləmələri etdikdən sonra əməliyyatı təsdiqləyin. Formanın idxalı cari açıq formanı bağlayacaq",
          
        defaultTodaysDateWithTime :"Defolt olaraq bu günün tarixi və vaxtı",
          
        filterVisible :"Sütun filtrləməni göstər",
          
          
        updatetableMaxQuantity : "Maksimum yenilənə bilən miqdar",
          
        hideQuantity : "Miqdar seçimini gizlət",
          
        disableQuantityTextbox : "Miqdar yazısını deaktiv et",
          
        multiplesOfQuantity : "Miqdarın bölmələri olaraq artır",
          
        justnumber : "Yalnız rəqəm",
          
        paymentHourWarning : "Çekout saatları daxilində ödəniş edə bilməzsiniz.",
          
        addColumn: "Sütun əlavə et",
          
        mailSettings : "Poçt parametrləri",
          
        newTaskMailDetail : "Yeni tapşırıq təyin edildikdə göndəriləcək poçt mətni",
          
        updateTaskMailDetail : "Tapşırıq redaktə edildikdə göndəriləcək poçt mətni",
          
        taskOnDropMail : "Tapşırıq yer dəyişdirdikdə göndəriləcək poçt mətni",
          
        selectSMTP : "SMTP ünvanı seçin",
          
        taskCreator :"Tapşırığı təyin edən şəxs",
          
        taskEndDate :"Tapşırığın son tarixi",
          
        tags :"Etiketlər",
          
        taskStartDate: "Tapşırığın başlama tarixi",
          
        taskAssigned : "Tapşırıq təyin edilmiş şəxs",
          
        taskDetail : "Tapşırıq təfərrüatı",
        
  
        taskSubject: "Tapşırıq Başlığı",
  
        taskPri :"Tapşırıq Prioriteti",
          
        taskEdit : "Tapşırığı Yenilə",
          
        addTask :"Tapşırıq Əlavə Et",
          
        taskStatus : "Tapşırıq Statusu",
          
        excelExportSettings :"Excel İxracı Ayarları",
          
        excelExportRequiredColor : "Tələb Olunan Sahə Sütunu Rəngi",
          
        allDataExcelExport : "Məlumatları Excel Şəklində İxrac Et",
          
        warningApproveImportReport :"Tənzimləmələri etdikdən sonra əməliyyatı təsdiqləyin. Hesabatın idxalı cari açıq hesabatı bağlayacaq.",
          
        reportName: "Hesabat Adı",
        emptyReportName: "Zəhmət olmasa Hesabatın Adını Sağ tərəfdən əlavə edin.",
          
        old : "köhnə",
          
          
        upperNode :"Yuxarı Fraqmentasiya",
          
        pdfViewStaticPdf : "Sabit Faylı Göstər",
          
        actions : "Əməliyyatlar",
          
        loading : "Yüklənir",
          exportDataToExcell : "Bütün Məlumatları Excelə İxrac Et",
          
        showRefreshIcon :"Yenilə Düyməsini Göstər",
          
        headerFilter : "Başlıq Filtri",
          
        filterRowVisible : "Sütunda Axtarış Qutusu Görünür",
          
        password : "Parol",
          
        categories:"Kateqoriyalar",
          
        pressEnter : "Enter Düyməsinə Basın",
          
        historyColumnValue : "Məlumat Tarixi Sütunu",
          
        hideMobile : "Mobil Cihazlarda Gizlənir",
          
        align : "Hiza",
          
        theme : "Tema",
          
        info : "Məlumat",
          
        showTitle : "Başlığı Göstər",
          
        purple : "Bənövşəyi",
          
        brown : "Qəhvəyi",
          
        yellow : "Sarı",
          
        turnOnComments : "Rəy Yandır",
          
        showContent : "Məzmunu Göstər",
          
        canBeHidden : "Gizlədilə bilər",
          
        allowEdit : "Redaktəyə İcazə Ver",
          
        allowDelete: "Silinməyə İcazə Ver",

        allowAdd: "Əlavəyə icazə ver",

        allowCopy: "Köçürməyə İcazə Ver",
          
        toastDeleteErrorMessage : "Bu komponent Form Tərcihləri skriptlərində istifadə edilir. Silinə bilməz.",
          
        formOpeningMultiElementEvent : "Formanın Çox-elementli Açılması Tədbiri",
          
        resetDatagridLocalSettings: "Məlumat Cədvəlinin Yerli Ayarlarını Sıfırla",
          
        loginP1:"River korporativ yaddaşınız kimi bütöv bir yaddaş sistemidir və təşkilatınızın və ya müəssisənizin keçmiş təcrübələri, uğurları, uğursuzluqları, öyrənilmiş dərslər, mədəniyyəti və dəyərləri kimi məlumatları içərir ki, gələcək qərar və fəaliyyətlər üçün istifadə edilə bilər. prosesləri, işçilərin bilikləri, müştəri təcrübələri, məhsul və xidmətlərin inkişafı və başqa bir çox məlumat. Bu məlumat şirkətin gələcək məqsədlərini və strategiyalarını rəhbər aparacaq.",
          
        loginP2 : "River iş proseslərini veb və mükəmməl təbii mobil tətbiqi dəstəklənən struktur ilə daha effektiv və sürətli icra etməyinizə kömək edir. Çatmağa ehtiyacınız olan hər şey sizin kompüteriniz, planşetiniz və ya smartfonunuz qədər yaxındır.",
          
        loginConnect :"Birləş",
          
        loginText: "Daxil ol",
          
        loginRightMore:"Daha çox",
        
        loginHeaderP1: "Şirkətinizin Yaddaş Mənbəyi",
        loginHeaderP2: "Hərəkətlilik",
        loginHeaderP3: "Qrafik Gücü",
        kanbanaddTasks: "Kanban Tapşırıq Əlavə Et",
        tasksstatusDesciption: "Kanban tapşırığının təyin ediləcəyi sütunun adını göstərir.",
        taskssubjectDescription: "Kanban tapşırığının başlığını daxil etməyə imkan verir. Kanban başlığı tələb olunan sahədir.",
        taskassignedToTask: "Tapşırıqlar üçün əlavə olunmuş qruplar",
        taskassignedTaskDescription: "Kanban tapşırığının təyin olunacağı qrupların seçilməsinə imkan verir.",
        addedForTasks: "Tapşırıq üçün əlavə edildi",
        taskAssignedInfoDescription: "Tapşırıq üçün bildirilməsi lazım olan insanlar",
        assignedInfo: "Bildiriləcək insanlar",
        groupInfo: "Tapşırıq üçün Bildiriləcək Qruplar",
        sortEnd: "Bitmə Tarixinə görə sırala",
        sender: "Göndərən",
        forward: "Ötürmək",
        attachments: "Əlavələr",
        loginP3: "Kanban və Gantt qrafikləri layihəni və ya iş axını görsəlləşdirmək üçün son dərəcə faydalı alətlərdir. Onlar iş elementlərinin cari statusunu və işin müxtəlif dövrlərindəki iş axarını görsel olaraq təqdim edirlər. Bu, qulaqlanmaları müəyyənləşdirmək, işləri prioritetləndirmək və əməliyyat effektivliyini artırmağı asanlaşdırır. Kanban və Gantt qrafikləri dinamik layihələri idarə etmək üçün tətbiqi və effektivdir, yazılım inkişafı və digər davamlı təkmilləşdirmə proseslərini idarə etmək üçün də praktik və effektivdir.",
        taskdetailDescription: "Kanban tapşırığının təfərrüatlarını daxil etməyə imkan verir. Kanban təfərrüatı tələb olunan sahədir.",
        taskassignedDescription: "İstifadəçilərə Kanban tapşırığının təyin edilməsinə imkan verir. Ən azı bir istifadəçinin Kanban tapşırığına təyin olunması lazımdır.",
        taskstartdateDescription: "Kanban tapşırığının başlama tarixini seçməyə imkan verir. Kanban'da, başlama tarixi avtomatik olaraq tapşırığın yaradılma vaxtını götürür.",
        taskenddateDescription: "Kanban tapşırığının bitmə tarixini seçməyə imkan verir. Kanban'da, tapşırığın bitmə tarixi avtomatik olaraq tapşırığın yaradılma tarixindən bir həftə sonra təyin edilir.",
        taskpriDescription: "Kanban tapşırığının prioritet statusunu seçməyə imkan verir. Kanban'da, tapşırığın başlama tarixi avtomatik olaraq tapşırığın yaradılma vaxtından bir həftə sonra götürülür.",
        tagsDescription: "Kanban tapşırığının etiketlərini əlavə etməyə imkan verir. Etiketlər vergüldən ayıraraq əlavə edildikdə bir-birindən ayrılır.",
        outlookDescription: "Tapşırığı Outlooka əlavə etmə seçildikdə, Outlook hesabınıza daxil olmaqla tapşırıq avtomatik olaraq təqvimə əlavə edilir.",
        taskAssInfoDescription: "Bu, tapşırığa təyin edilməyən, ancaq məlumatları tələb edilən insanlar üçün istifadə olunan sahədir. Məlumat vermək üçün əlavə olunan insanlar tapşırıqlarda əməliyyat apara bilməz.",
        receiver: "Qəbul edən",
        draft: "Qaralama",
        spam: "Təhlükəsiz olmayan Email",
        filterstate: "Statusa görə süz",
        checkForspelling: "Orfoqrafi səhvlərinə yoxla",
        emailToForward: "Ötürüləcək Email",
        lowImpMails: "Aşağı Təhmiyyəli Email",
        normalImpMails: "Normal Təhmiyyəli Email",
        highImpEmails: "Yüksək Təhmiyyəli Email",
        showReadMails: "Oxunmuş Email-ları göstər",
        groupinfoDescription: "Bu, bir tapşırığa təyin edilməyən, ancaq məlumatları tələb edilən qruplar üçün istifadə edilən sahədir. Bildirilməsi üçün əlavə olunan qruplara üzvlük edən insanlar tapşırıqlarda əməliyyat apara bilməz.",
        timeoutMission: "Tapşırığın sona çatmasından əvvəl xəbərdarlığın başlama vaxtı",
        timeoutmissionDescription: "Kanban tapşırığı sona çatdıqda xəbərdarlığın başlama vaxtını təyin etmək üçün istifadə edilir.",
        taskperiodDescription: "Kanban tapşırığının son tarixinin yaxınlaşması zamanı təkrarlanan xatırlatma vaxtını təyin etmək üçün istifadə edilir.",
        taskProgress: "Tapşırıq İcmalı",
        taskProgressDescription: "Bir Kanban tapşırığının inkişafını göstərmək üçün istifadə olunur. İcmal Gantt qrafikində görülə bilər.",
        updatekanbanTask: "Kanban Tapşırığını Yenilə",
        taskcreatorDescription: "Kanban tapşırığının təyin edənin göstərilməsinə imkan verir.",
        uploadfileDescription: "Kanban tapşırığına faylların yüklənməsi üçün istifadə olunur. Fayllar və ya şəkillər DMS-də qeyd olunur.",
        infoPersons: "Xəbərdar Ediləcək Şəxslər",
        warningtime: "Xəbərdarlıq Vaxtı",
        taskPeriod: "Tapşırıq Dövrü",
        taskProgresSatus: "Tapşırıq İcmalı",
        makeTask: "Tapşırıq Yaradan",
        addedforTasks: "Tapşırıq üçün əlavə edildi",
        outlookIntegration: "Outlook İnteqrasiyası",
        loginoutlook: "Outlook Təqviminə Daxil Ol",
        fetchoutlook: "Outlook Təqvim Məlumatlarını Al",
        progressStatus: "İcmal Statusu",
        taskColors: "Tapşırıq Rəngləri",
        addcalendarTask: "Tapşırıqları Təqvime Əlavə Et",
        updatetaskCalendar: "Tapşırıq Təqvimini Yenilə",
        statusComplete: "Tamamlanma Statusunu Süz",
        priorityFilter: "Prioritet Süzü",
        columnFilter: "Sütun Süzü",
        assignPerson: "Tapşırılan Şəxslər",
        assignGroup: "Tapşırılan Qruplar",
        noTask: "Heç bir tapşırıq tapılmadı",
        resetSort: "Sıralamanı Sıfırla",
        
  sortASC: "A - Z sırala",
sortDESC: "Z - A sırala",
sortAppointment: "Tapşırığın təyin edildiyi tarixə görə sırala",
trash: "Zibil",
showOlderMail: "Əvvəlcəki Məktubları Göstər",
mailNotFound: "Məktub Tapılmadı",
sendNewMail: "Yeni Məktub Göndər",
showUnreadMails: "Oxunmamış Məktubları Göstər",
updateColumnMail: "Sütuna tapşırıq əlavə olunduqda əlaqəyə mesaj göndər",
addTaskToOutlook: "Tapşırığı Outlooka əlavə et",
enterYourMessage: "Mesajınızı daxil edin",
reminder: "Xatırlatma",
determinedToTask: "Tapşırıq üçün müəyyən edilmiş vaxt",
remainingTime: "Qalan vaxt",
hours: "Saat",
withoutPriority: "Prioritet yoxdur",
lowPri: "Aşağı prioritet",
normalPri: "Orta prioritet",
highPri: "Yüksək prioritet",
showAllTasks: "Hamısını göstər",
showIncompleteTasks: "Tamamlanmamışları göstər",
showCompeletedTasks: "Tamamlanmışları göstər",
reportDesigner: "Hesabat Dizayneri",
fileManagementSystem: "Fayl İdarəetmə Sistemi",
formDesigner: "Forma Dizayneri",
boardDesigner: "Lent Dizayneri",
themeSettings: "Tema Ayarları",
social: "Sosial",
profile: "Profil",
contacts: "Əlaqələr",
files: "Fayllar",
pages: "Səhifələr",
noResultsFound: "Heç bir nəticə tapılmadı",
processes: "Proseslər",
filterByStatus: "Statusa görə süz",
filterByPri: "Prioritete görə süz",
filterByCol: "Sütuna görə süz",
todoAddTasks: "To-Do Tapşırıqları Əlavə Et",
addLabel: "Etiket daxil et",
showWithSubTasks: "Alt tapşırıqları göstər",
riverBoard: "River Lentləri",
notify: "Bildirişlər",
likedYourPost: "Paylaşımınızı bəyəndi",
likedYourComment: "Şərhinizi bəyəndi",
systemNotify: "Sistem Bildirişləri",
readAllNotify: "Bütün Bildirişləri Oxu",
recommendedUsers: "Təklif Edilən İstifadəçilər",
suggestedPosts: "Təklif Edilən Paylaşımlar",
sPostReposted: "Təkrar paylaşıldı",
shareSomething: "Bir şey paylaşın",
updatePost: "Paylaşımı Yenilə",
ifYouDoNotSelectGroup: "Qrup seçməsəniz, hər kəs paylaşımınızı görə bilər",
addComment: "Şərh əlavə et",
sendComment: "Şərhi göndər",
areYouSureYouWantToDeleteThisPost: "Bu paylaşımı silmək istədiyinizdən əminsinizmi",
yes: "Bəli",
no: "Xeyr",
deletePost: "Paylaşımı sil",
postSuccesfullyDeleted: "Paylaşım uğurla silindi",
deleteComment: "Şərhi sil",
areYouSureYouWantToDeleteThisComment: "Bu şərhi silmək istədiyinizdən əminsinizmi",
commentSuccesfullyDeleted: "Şərh uğurla silindi",
sharePost: "Paylaşımı paylaş",
areYouSureYouWantToShareThisPost: "Bu paylaşımı paylaşmaq istədiyinizdən əminsinizmi",
repostedSuccessfully: "Uğurla təkrar paylaşıldı",
upload: "Yüklə",
refresh: "Təzələ",
allowedExtentions: "İcazə verilmiş əlavələr: JPG, GIF və ya PNG. Maksimum fayl həcmi",
systemLandingPagePreference: "Qoşulma səhifəsi Tənzimləyi",
allownonadminuserstosearchforotherusers: "Qoşulma səhifəsi Tənzimləyi",
passwordChange: "Şifrə Dəyişikliyi",
newPassword: "Yeni Şifrə",
retypethenewpassword: "Yeni şifrəni təkrar daxil edin",
notifications: "Bildirişlər",
showWhoLikesYourPost: "Kimlərin paylaşımınızı bəyəndiyini göstər",
showWhoLikesYourComment: "Kimlərin şərhinizi bəyəndiyini göstər",
aboutMe: "Mən haqqımda",
bio: "Öz həyatı",
dateOfBirth: "Doğum tarixi",
website: "Veb sayt",
phone: "Telefon",
saveChanges: "Dəyişiklikləri saxla",
username: "İstifadəçi adı",

content: "Məzmun",
projectManagement: "Proyekt İdarəsi",
addPage: "Səhifə əlavə et",
addFolder: "Qovluq əlavə et",
showUsers: "Görə bilən istifadəçilər",
showGroups: "Görə bilən qruplar",
readonlyUsers: "Yalnız oxuya bilən istifadəçilər",
readonlyGroups: "Yalnız oxuya bilən qruplar",
columnHeight: "Sütun hündürlüyü",
showKanbanCount: "Kanban miqdar məlumatını göstər",
kanbanSettings: "Kanban Ayarları",
gantSettings: "Gant Ayarları",
schedulerSettings: "Təqvim Ayarları",
showRowLines: "Sətir xətlərini göstər",
activateOutlookConnectio: "Outlook Bağlantısını Aktivləşdir",
selectOutlookColumn: "Outlook sütununu seç",
selectSMTPopoverText: "Kanban, Gant, Təqvim və Yaşıl cüt komponentlərində istifadə olunacaq E-mail SMTP si seçildi.",
menuFilePathId: "River Menyu qovluğu ID-si",
recycleBinPathName: "River Qarışıq Qovluğu Adı",
recycleBinPathId: "River Qarışıq Qovluğu ID-si",
fileNameChangedMessage: "Fayl adı uğurla dəyişdirildi.",
fileNameChangedErrorMessage: "Fayl adı dəyişdirilə bilmədi.",
fileCreatedMessage: "Fayl uğurla yaradıldı.",
newTaskMailDetailPopoverText: "Yeni tapşırıq Kanban, Gant, Təqvim və Yaşıl cüt komponentlərində təyin edilərsə, təyin olunan istifadəçilərə göndəriləcək avtomatik E-mailin məzmununun bir hissəsi.",
taskOnDropMailPopoverText: "Tapşırıq daxil edən sütun dəyişdirildikdə, Kanban, Gant, Təqvim və Yaşıl cüt komponentlərinə göndəriləcək avtomatik E-mailin məzmununun bir hissəsi.",
createdBy: "Yaradan",
updatedBy: "Yenilənmiş",
createdDate: "Yaradılma tarixi",
updatedDate: "Yenilənmə tarixi",
folderPermissionSettings: "Fayl Yetkiləri Ayarları",
quantity: "Miqdar",
fileManagementPermissionSettings: "Fayl İdarəetmə Yetkiləri Ayarları",
fileNotFoundErrorText: "Axtardığınız kriteriyalara uyğun heç bir fayl tapılmadı.",
updateTaskMailDetailPopoverText: "Əlaqədar tapşırıqda Kanban, Gant, Təqvim və Yaşıl cüt komponentlərində dəyişikliklər edildiyi zaman təyin olunan istifadəçilərə göndəriləcək avtomatik E-mailin məzmununun bir hissəsi.",
selectOutlookColumnPopoverText: "Outlook vasitəsilə əlavə olunacaq tapşırıqların daxil ediləcəyi sütun seçildi.",
createFolder: "Fayl yarat",
versionHistory: "Versiya tarixi",
deletePermanently: "Həmişəlik sil",
recover: "Geri qaytar",
groupPermissions: "Qrup Yetkiləri",
allowUpload: "Fayl yükləməyə icazə ver",
allowView: "Fayl baxışına icazə ver",
allowDownload: "Fayl endirməyə icazə ver",
permissionType: "İcazə Növü",
applyToSubFolders: "Hamı alt qovluqlara tətbiq edilsin",
newFolderName: "Yeni Fayl Adı",
tagTips: "Qeydlər # işarəsi ilə başlamalıdır. Yalnız rəqəm və hərf içərməlidir",
addAsNewVersion: "Yeni versiya olaraq əlavə et",
addAndChangeName: "Yüklə və adı dəyişdir",
skip: "Keç",
fileExistError: "Eyni adlı fayl hedef qovluqda mövcuddur",
processFileShow: "River Proses Qovluğunu göstər",
processFilePathName: "River Proses Qovluğu adı",
processFilePathId: "River Proses qovluğu ID-si",
menuFileShow: "River Menyu Qovluğunu göstər",
menuFilePathName: "River Menyu Qovluğu adı",
fileCreatedErrorMessage: "Fayl yaratmağa çalışarkən xəta baş verdi.",
fileDeletedMessage: "Fayl silmə tamamlandı.",
fileDeletedErrorMessage: "Faylı silmək mümkün olmadı.",
fileMovedMessage: "Faylın köçürülməsi tamamlandı.",
fileMovedErrorMessage: "Faylın köçürülməsi alınmadı.",
fileCopyMessage: "Faylın kopyalanması tamamlandı.",
fileCopyErrorMessage: "Faylın kopyası alınmadı.",
fileDownloadMessage: "Faylın yüklənməsi tamamlandı.",
fileDownloadErrorMessage: "Faylın yüklənməsi alınmadı.",
fileSizeShowErrorMessage: "Fayl böyüklüyü çox böyük olduğu üçün baxmaq mümkün deyil. Xahiş edirik faylı yükləyin.",
unsupportedFile: "Dəstəklənməyən fayl",
fileNameError: "Bu adla bir fayl mövcuddur, xahiş edirik başqa bir adla yenidən cəhd edin.",
fileGivePermissionMessage: "Faylın icazəsi uğurla verildi.",
fileGivePermissionErrorMessage: "Fayl icazə verilməsində səhv. Qeydiyyat alınmadı.",
groupName: "Qrup adı",
createGroup: "Qrup yarat",
conversation: "Söhbətlər",
startConversation: "Söhbət başlat",
userNotFound: "Əlaqə tapılmadı",
chatNotFound: "Söhbət tapılmadı",
themeTypeLight: "Açıq",
themeTypeBorderedLight: "Çərçivəli açıq",
themeHalfDark: "Yarım tünd",
themeDark: "Tünd",
themeRouteAnimation: "Keçid animasiyası",
themeRouteAnimationFadeInLeft: "Sol tərəfdən gözlə-gözlə",
themeRouteAnimationZoomIn: "Böyütmə",
themeRouteAnimationFadeIn: "Gözlə-gözlə",
themeRouteAnimationNone: "Heçbir",
themeMenuType: "Menyu tipi",
themeMenuTypeHorizontal: "Üfüqi",
themeMenuTypeVertical: "Şaquli",
themeMenuCollapse: "Dar menyu",
themeNavbarColor: "Naviqasiya paneli rəngi",
themeNavbarType: "Naviqasiya paneli tipi",
themeSticky: "Yapışqan",
themeStatic: "Statik",
themeFloating: "Süzgün",
themeHidden: "Gizli",
themeFooterType: "Alt hissənin tipi",
catalog: "Kataloq",
riverBoards: "Çay Taxta",
clipboardDesigner: "Mübadilə Dizayneri",
designMode: "Siz Dizayn Rejimindəsiniz",
editPage: "Səhifəni düzəlt",
rename: "Adı dəyiş",
exportToPdf: "PDF olaraq çıxar",
exportToExcel: "Excel olaraq çıxar",
revenueReport: "Gəlir Hesabatı",
header: "Başlıq",
connection: "Bağlantı",
primaryKey: "Əsas açar",
secondaryKey: "İkinci Açar",
completedKey: "Tamamlanmış Açar",
countKey: "Say Açarı",
statusKey: "Status Açarı",
progressConnection: "Əlaqənin Gedişi",
progressScript: "Gediş Skripti",
progressPrimaryKey: "Əsas Gediş Açarı",
progressCountKey: "Gediş Sayı Açarı",
progressPercentageKey: "Faiz Gediş Açarı",
progressColorKey: "Rəng Gediş Açarı",
keyColumn: "Qiymət Sütunu",
currency: "Valyuta",
whitePage: "Ağ Səhifə",
noReportSelected: "Hesabat seçilmədi",
noTypeSelected: "Hesabat Növü seçilmədi",
whitePageReports: "Ağ Səhifə Hesabatı",
addBranch: "Şöbə əlavə et",
branchName: "Şöbə adı",
rowNumber: "Sıra nömrəsi",
reportTypeSelect: "Hesabat Növünü seçin",
reportDescription: "Hesabatın təsviri...",
useWeb: "Vebdə istifadə et",
useMobile: "Mobil üçün istifadə et",
mainScript: "Əsas Skript",
prerunScript: "Əvvəlki skripti işlət?",
preScript: "Əvvəlki Skript",
testIt: "Test et",
selectAll: "Hamısını seç",
addReport: "Hesabat əlavə et",
selectReportType: "Hesabat Növünü seçin",
reportType: "Hesabat Növü",
reportDesingPermission: "İstifadəçinin hesabat dizayn etmə icazəsi yoxdur.",
reportImportedOpenLeftMenu: "Hesabat idxal edildi. Soldan menyudan aça bilərsiniz.",
forReportExport: "Hesabat idxal etmək üçün əvvəlcə bir hesabat seçməlisiniz.",
anErrorOccurred: "Səhv baş verdi",
reportDeleted: "Hesabat silindi",
branchDeleted: "Şöbə silindi",
addBranchName: "Zəhmət olmasa şöbə adını daxil edin",
addedBranch: "Şöbə əlavə edildi",
selectConnectionAddress: "Zəhmət olmasa bir bağlantı ünvanı seçin",
enterSqlScript: "Zəhmət olmasa SQL skriptini daxil edin",
enterReportName: "Zəhmət olmasa hesabat adını daxil edin",
reportAdded: "Hesabat əlavə edildi",
numberOfDataToBeProcessed: "Bağlantı uğurla yerinə yetirildi. Proses ediləcək məlumatların sayı:",
dataNotFoundCheckYourConnection: "Məlumat tapılmadı! Xahiş edirik bağlantınızı və SQL əmrinizi yoxlayın",
connectionNotFoundCheckYourConnection: "Bağlantı alınmadı! Xahiş edirik bağlantınızı və SQL əmrinizi yoxlayın",
fillConnectionAndSql: "Zəhmət olmasa bağlantınızı və SQL əmrinizi doldurun",
allTypesActivate: "Bütün Növlər Görünüş Zamanı Aktiv Edilir",
passwordRequired: "Parol tələb olunur",
showLess: "Daha az göstər",
showMore: "Daha çox göstər",
authority: "Yetki",
preScriptDescription: "Əvvəlki skriptin təsviri",
enterReportFilters: "Hesabat süzgüsünü daxil edin",
gridDesigner: "Cədvəl Dizayneri",
addMainBranch: "Əsas Şöbə əlavə et",
resetSettings: "Tənzimləmələri sıfırla",
columsToGroup: "Verini qruplamaq üçün sütun başlıqları kontekst menyusundan istifadə edin",
customize: "Əlavə tənzimləmələr",
colorType: "Rəng Növü",
formatManagement: "Format İdarəetməsi",
bottomTotalColumn: "Alt Cədvəl Sütunu",
formatType: "Format Növü",
datagridSettingsReset: "Məlumat cədvəli tənzimləmələri sıfırlanacaq",
unexpectedError: "Üzr istəyirik, gözlənilməz bir səhv baş verdi.",
changesSaved: "Dəyişikliklər yadda saxlanıldı",
addGantTask: "Gant Vəzifəsini əlavə et",
updGantTask: "Gant Vəzifəsini yenilə",
updSchedulerTask: "Takvim Vəzifəsini yenilə",
updTodoTask: "Tapşırığı yenilə",
completeTask: "Vəzifəni tamamla",
cantBeEmptyKanban: "Vəzifə başlığı, vəzifə detalları və vəzifəni təyin edən şəxs boş ola bilməz",
successAdd: "Vəzifə uğurla əlavə edildi",
successUpd: "Vəzifə uğurla yeniləndi",
statusChangeTodo: "Tapşırığın tamamlanma statusu uğurla dəyişdirildi.",
reminderSended: "Xatırlatma e-poçtu tapşırıq üçün uğurla göndərildi",
askForCompleteTask: "Həqiqətən vəzifəni tamamlamaq istəyirsiniz?",
infoForCompletedTask: "Vəzifənizin alt-vəzifələri də tamamlanacaq.",
noPermisison: "Siz yetkiləndirilmisiniz.",
successDependencyAdded: "Vəzifə bağlılığı uğurla əlavə edildi",
filterimportant: "Əhəmiyyətə görə süzün",
enterEmail: "E-poçt ünvanını daxil edin",
reply: "Cavabla",
newCol: "Yeni Sütun",
thickness: "En",
distanceToUpperComponent: "Üst komponentə olan məsafə",
companyName: "Şirkət adı",
showEmailModule: "E-poçt modulunu göstər",
distanceToBottomComponent: "Alt komponentdən olan məsafə",
canNonAdminUsersPost: "Admin olmayan istifadəçilər paylaşa bilər",
columnColor: "Sütun Rəngi",
byWho: "kim tərəfindən",
updated: "Yeniləndi",
commentAdded: "Şərh əlavə edildi",
completed: "Tamamlandı",
addRemoveColumns: "Sütun əlavə et/sil",
searchMessage: "Mesajları axtar",
riverReports: "River BI Hesabatları",
forms: "Formalar",
searchinProcess: "proseslərdə axtarış",
searchVoiceMessage: "Axtardığınız mesaj bu səs faylının içindədir",
searchVoiceMessages: "Səs poçtunu axtarır",
showSocialNotifications: "Sosial Bildirişləri göstər",
showProjectManagementNotifications: "Proyekt İdarəetmə Bildirişləri göstər",
showProcessNotifications: "Proses Bildirişlərini göstər",
pageDesingPermission: "İstifadəçinin səhifə dizayn etmə icazəsi yoxdur.",
filemanagementDesingPermission: "İstifadəçinin fayl idarə etmə icazəsi yoxdur.",
taskDelete: "Vəzifə silinəcək.",
tasksWillDeleted: "Əminsinizmi ki, vəzifəni silmək istəyirsiniz?",
componentSettings: "Komponent Tənzimləmələri",
aboutComponent: "Komponent haqqında",
sureSaveChanges: "Dəyişiklikləri yadda saxlamağınızdan əminsiniz?",
showDeletedNotifications: "Silinmiş Bildirişləri Göstər",
shareSometthing: "Nəsə paylaş",
resetPageLayout: "Səhifə Dizaynını Sıfırla",
downloadingSS: "Ekran şəkili yüklənir. Zəhmət olmasa gözləyin.",
showHorizontalLines: "Üfüqi Xətləri Göstər",
        turnOffVerticalHide: "şaquli gizlənməni söndürün",
        whichVerticalLinesAreToBeHidden: "Hansı Şaquli Xətlər Gizlədilməlidir",
        priority: "Prioritet",
        desingMode: "Dizayn rejimindəsiniz",
        boardSidebarMessageField: "Tapşırıq Mesaj Sahəsi",
        boardSidebarExtraField: "Tapşırıq Əlavə Komponent Sahəsi",
        boardSidebarTaskHistory: "Tapşırıq Tarixi",
        loadMoreNotifications: "Daha Çox Bildiriş Göstər",
        deletedNotifications: "Silinmiş Bildirişlər",
        noFormNotification: "Hazırda Proses Bildirişi Yoxdur",
        noDeletedFormNotifications: "Silinmiş Proses Bildirişi Yoxdur",
        noDeletedBoardNotification: "Silinmiş Panel Bildirisi Yoxdur",
        noBoardNotification: "Hazırda Panel Bildirisi Yoxdur",
        noDeletedSocialNotification: "Silinmiş Sosial Media Bildirimi Yoxdur",
        noSocialNotification: "Hazırda Sosial Media Bildirimi Yoxdur",
        deleteAll: "Hamısını Sil",
        markAllRead: "Hamısını Oxunmuş Olaraq İşarələ",
        board: "Panoya",
        form: "Forma",
        formNotifications: "Form Bildirimləri",
        boardNotifications: "Pano Bildirimləri",
        socialNotifications: "Sosial Bildirimlər",
        email: "E-poçt",
        oldFormName: "Köhnə Form Adı",
        newFormName: "Yeni Form Adı",
        oldFormVariable: "Köhnə Form Dəyişəni",
        newFormVariable: "Yeni Form Dəyişəni",
        oldCategory: "Köhnə Kateqoriya",
        newCategory: "Yeni Kateqoriya",
        connectionInFile: "Faylda Əlaqə",
        yourConnection: "Sizin Əlaqəniz",
        element: "Element",
        script: "Skript",
        formClosingEvent: "Formun Bağlanma Hadisesi",
        message: "Mesaj",
        formExportTextError: "Forma əlavə olunmuş komponent yoxdur və ya forma yaddaşa verilməyib. Əməliyyatın səhv baş verməməsi üçün bu səhvə düzəliş edin.",
        checkRequiedFields: "Məcburi Sahələri Yoxlayın",
        dontCheckRequiredFields: "Məcburi Sahələri Yoxlamayın",
        createPdfWarning: "İşarələndiyi təqdirdə, forma yaddaşa verilməyəcək və bağlanmayacaq",
        cratePdf: "PDF Yarat",
        identityNumber: "Təyinat Kodu",
        minPaymentHour: "Minimum Saat",
        maxPaymentHour: "Maksimum Saat",
        darkBlue: "Tünd Mavi",
        orange: "Narıncı",
        component: "Komponent",
        properties: "Xüsusiyyətlər",
        dmsPath: "DMS Yolu",
        dashboard: "İdarə Paneli",
        slider: "Slider",
        list: "Siyahı",
        otherImages: "Digər Şəkillər",
        unitScript: "Vahid Skripti",
        updatableMaxQuantity: "Yenilənən Maks. Miqdar",
        newPage: "Yeni Səhifə",
        code: "Kod",
        resetHiddenComponent: "Gizlənmiş Məqsəd Komponentlərinin Dəyərini Sıfırla",
        bold: "Qalın",
        italic: "İtalik",
        stringFormat: "Sətir Formatı",
        videoUploaded: "Video Yükləndi",
        fillInAllFields: "Bütün Sahələri Doldurun",
        normalPaging: "Normal Səhifələmə",
        pageSize: "Səhifə Ölçüsü",
        special: "Xüsusi",
        link: "Link",
        fontWeight: "Yazı Tipinin Çəkisi",
        pdfFileNotFound: "PDF Faylı Tapılmadı",
        themeMenuHidden: "Gizli Menyu",
        likedOn: "bəyəndim",
       
        subCategory: "Alt Kateqoriya",
        toTheBoard: "lövhəyə",
        hideTaskDetail: "Tapşırıq Təfərrüatlarını Gizlə",
        selectData: "Məlumatı seç",
        noContentYet: "Hələ Məzmun Yoxdur",
        addRowBetween: "Araya Sətir Əlavə Et",
        userPermissions: "İstifadəçi İcazələri",
        riverModules: "RIVER Modulları",
        processHistory: "proses tarixi",
        commentHasBeenMadeAtThisStage:"Bu mərhələdə şərh əlavə edildi",
        askForPassword: "Parol soruşulsun mu?",
        passwordIsWrong: "Parol yanlışdır, xahiş edirəm yenidən cəhd edin.",
        pleaseEnterPassword: "Davam etmək üçün şifrənizi daxil edin.",
        onePaymentEachSave: "Hər qeyd üçün tək ödəniş",
        clearSelection: "Seçimi Sil",
        allowDownloadVersionHistoryDocuments: "Versiya Tarixi Sənədlərini Yükləməyə İcazə Ver",

        delegated: "həvalə edilmiş",
        openDocxToPdf: "Faylı PDF olaraq aç",
        activationStartDate: "Aktivasiya Başlama Tarixi",
        activationEndDate: "Aktivasiya Bitmə Tarixi",
        changeActivationDate: "Aktivasiya Tarixini Dəyişdir",
        showArchive: "Arxiv Göstər",
        hideArchive: "Arxiv Gizlət",
        tasks: "Tapşırıqlar",
        maxFileCountLimit: "Maksimum Fayl Sayı",
        fileCountLimit: "Fayl Sayı Sınırı",
        numberOfFilesExceeded: "Faylların sayı aşıldı",
        numberOfFilesLimit: "Fayl sayı sınırı",
        lineIntermediateAllowAdd: "Orta sətri əlavə et",
        lineIntermediateAllowCopy: "Orta sətri kopyala",
        triggerReport: "Tətik hesabatı",
        setWhitePageReportExportName: "ağ səhifə hesabatının ixrac adını təyin edin",
        menuRowUpdated: "Bu avtomatik artan verisi ilə qeydiyyatlı veri mövcuddur. Avtomatik artan nömrəsi avtomatik güncəlləndi: ",
        isMenuRowControl: "Menyu Sətrinin Nəzarəti",
        sqlScriptWarning: "Bu sahəni istifadə etmək performans itkisinə səbəb ola bilər.",

        deletedTask: "Silinmiş Tapşırıq",
        getDeletedTasks: "Silinmiş Tapşırıqları Əldə Et",
        getTasks: "Tapşırıqları Əldə Et",
        movePageDesignersNode: "Seçilmiş Pano/Menü Klasöre Taşınacaq",
        informationAboutMoveToDirectory: "Götürülən Pano/Menü-nün istifadəçi icazələri, onun köçürüldüyü qovluğun icazələrinə görə dəyişəcəkdir.",
        move: "Köçür",
        download: "Yüklə",
        create: "Yarat",
        statistic: "Statistika",
        priRate: "Əsaslı Sıralamaya Əsasən Təyinat Oranı",
        taskcreatorlist: "Tapşırıq Yaradanların Siyahısı",
        completionRate: "Tamamlanma Oranı",
        columnsTaskCount: "Sütunlardakı Tapşırıq Sayı",
        showKanbanFilter: "Kanban Filterlərini Göstər",
        processToStart: "Başlanacaq proses",
        fieldMatching: "Sahələrin uyğunluğu",
        dontLetDeleteWhatTheyAdded: "Öz əlavə etdiklərini silə bilərlər",
        myJobs: "Mənim işlərim",
        excelExportName:"excel ixrac adı",
        showFileHistory: "Fayl tarixinin göstərilməsi",
        filterSearch: "Tapşırıqlarda axtarış",
        filterForAssignUsers: "Tapşırıq təyin edənlərə görə süzgəc",
        filterForAssignUGroups: "Qrupa görə süzgəcləmək",
        filterForPri: "Prioritetə görə süzgəcləmək",
        clearFilter: "Süzgəcləri təmizlə",
        makeRequiredField: "Məcburi sahə et",
        makeReadOnly: "Yalnız oxumağa et",
        letItWorkProcessContinues: "Proses davam etdikdə işlə",
        event: "Hadisə",
        selectEvent: "Hadisəni seç",
        rowEditor: "Sıra redaktoru",
        formOpeningComponentEvent: "Formanın açılma komponent tədbiri",
        addedTask: "tapşırıq",
        updatedTask: "yeniləndi",
        deleteTask: "silindi",
        completedTask: "tamamlandı",
        boardToastNotification: "{{0}}      {{1}} cədvəlinə   {{2}}  tapşırığını  {{3}} ",
        socialToastNotification: "{{0}}  {{2}} saytında {{1}} məzmunlu postunuzu bəyəndi",
        processToastNotification: "{{0}}  {{1}} formasına şərh verdi",
        yesterday: "Dünən",
        today: "Bu gün",
        notSaved: "Qeyd edilmədi",
        editors: "Redaktorlar",
        basic: "Əsas",
        file: "Fayl",
        datagridPreferences: "Datagrid Tercihlər",
        deleteLetsAsk: "'Silinsin mi?' soruşsun.",
        addNewRow: "Soru olmadan, Yeni sətir əlavə etsin.",
        oldRowShouldBeDeleted: "Soru olmadan, Köhnə sətrlər silinsin.",
        canBeSearched: "Axtarış edilə bilər?",
        processName: "prosesin adı",
        removeReadOnly: "Yalnız oxuna bilən xüsusiyyəti sil",
        dontMakeRequired: "Məcburi sahə təyin etmə",
        dataName: "Məlumat Adı",
        margin: "Marja",
        rowCount: "Sıra Sayı",
        colCount: "Sütun Sayı",
        outsideMargin: "Xarici Marja",
        widgetName: "Widget adı",
        searchInProcess: "Proses formaları",

        changeEventScriptRun: "Məlumat doldurulduğunda dəyişiklik skriptləri işlənsin mi?",
        dontChanceId: "(Bu komponent form fəaliyyətlərində istifadə olunduğu üçün obyekt identifikasiyası dəyişdirilə bilməz.)",
        scriptButtonWarning: "Skript düyməsinin status parametrləri menyu formalarında dəstəklənmir.",
        description2: "bəyanat 2",
        showJobListTabs: "İş Siyahısı Nişanlarını göstərin",
        showUserPhotosInJobList: "İş Siyahısında İstifadəçi Şəkillərini göstərin",
        dmsVideoGaleryTabWarning: "Bu sahəni istifadə etmək üçün icazəniz yoxdur.",
        searchInFormJson: "json formasında axtarın",
        useExpandedJoblist: "Genişləndirilmiş iş siyahısından istifadə edin",
        editTour: "Turu düzənlə",
        selectComponent: "Komponenti seçin",
        tourComponents: "Tur Komponentləri",
        addTourElement: "Tur elementi əlavə et",
        startTourWhenFormOpen: "Forma açıldıqda turu başlat",
        compare: "Müqayisə",
        deleteTourElement: "Tur elementini sil",
        tourWillDeleted: "Tur silinəcək",
        tourDelete: "Tur sil",
        fixedRowHeight: "Sabit Sıra Hündürlüyü",
        pushWidgetItems: "Widget Əşyalarını İt",
        allowMultiLayer: "Çoxlu Təbəqə İcazəsi",
        gridType: "Grid Növü",
        useCollapsedJoblist: "yığışdırılmış iş siyahısından istifadə edin",
        searchAllData: "Bütün məlumatları axtarın",
        scriptsRunAllAreSelected: "Bütün seçilənlər işləndiyində skriptlər işlənsin?",
        
        menuSchedulerPermission: "Menyu kartı əlavə etmək icazəniz yoxdur",
        showOnTask: "Redaktor proseslərdə görünməlidirmi?",
        alreadyHaveMenu: "Artıq menyu komponenti var",
        select: "Seçin",
        startedUserName: "Başlanmış İstifadəçi Adı",
        startedUserId: "İstifadəçi ID-si başladı",
        processInstanceId: "Proses Nümunəsinin ID- si",
        formId: "Forma ID",
        min: "Minimum",
        difference:"İki Fayl Arasındakı Dəyişikliklər",       
        labelNotFound:"Bu dildə etiket müəyyən edilməyib",
        cleanElement: "Veri çap edərkən element sahəsini təmizləmək?",
        cleanColumn: "Veri çap edərkən sütun boşluğunu təmizləmək?"
    }
  
  }