import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
    providedIn: 'root'
})
export class EventEmitterService {
    invokeLogOutFunc = new EventEmitter();
    invokeSendFormFunction = new EventEmitter();
    invokeHistoryFunction = new EventEmitter();
    invokeFormChangesFunction = new EventEmitter();
    invokeSubmitFormFunction = new EventEmitter();
    invokeLayoutChangeFunction = new EventEmitter();
    invokeShowPopopFunction = new EventEmitter();
    invokeSchedulerInit = new EventEmitter();
    invokeChangeVisibleFunction = new EventEmitter();
    invokeChangeDisabledFunction = new EventEmitter();
    invokeChangeFilterOutElement = new EventEmitter();
    invokeColumnSumFunction = new EventEmitter();
    invokeDeletedFileIdsFunction = new EventEmitter();
    invokeMenuSubmitFunction = new EventEmitter();
    invokeNestedSelecboxFunction = new EventEmitter();
    invokeChangeEvent = new EventEmitter();
    invokeDatagridOpenEditRowPopupEvent = new EventEmitter();
    invokeGalleryChangeEvent = new EventEmitter();
    invokeButtonClick = new EventEmitter();
    invokeSaveSystemInfoEvent = new EventEmitter();
    invokeStartInstanceFunction = new EventEmitter();
    invokeStartMenuFunction = new EventEmitter();
    invokeTaskLinkFrontPanelFunction = new EventEmitter();
    invokeShowStickyEvent = new EventEmitter();
    invokeNavbarColor = new EventEmitter();
    invokeGetAllComponentFunction = new EventEmitter();
    invokeReadMessage = new EventEmitter();
    invokeUpdateTasksFuntion = new EventEmitter();
    invokeFavoriteChangeEvent = new EventEmitter();
    invokePageWithRouteChangeEvent = new EventEmitter();
    invokeChatName = new EventEmitter();
    invokeChangeMenuTabComponentEvent = new EventEmitter();
    invokeOpenFormEvent = new EventEmitter();

    invokeCreateTaskAndStartFormEvent = new EventEmitter();
    invokePostData = new EventEmitter();
    invokeSetLanguageEvent = new EventEmitter();

    invokeSetHomePopupVisibleEvent = new EventEmitter();

    invokeGetProcessInfo = new EventEmitter();
    invokeLoginParams = new EventEmitter();
    invokeCloseNavbarSearch = new EventEmitter();
    invokeSearchSetDataEvent = new EventEmitter();
    invokeColorSetDataEvent = new EventEmitter();
    invokeGetAllSchemaListEvent = new EventEmitter();
    invokeOnValueChageEventForReport = new EventEmitter();
    invokeRefreshGridEvent = new EventEmitter();
    invokeMenuChildByHomeEvent = new EventEmitter();
    invokeUpdateHomeChildEvent = new EventEmitter();
    invokeFormLoad = new EventEmitter();
    invokeHomeLoadingEvent = new EventEmitter();
    invokeOpenProfileSideBar = new EventEmitter();
    invokeProcessChageEvent = new EventEmitter();
    invokeCloseSettingsIcon = new EventEmitter();
    invokeOpenChildFormChageEvent = new EventEmitter();
    invokeOpenSearchPopupEvent = new EventEmitter();
    invokeOpenHistoryChildFormChageEvent = new EventEmitter();
    invokeSelectedChats = new EventEmitter();
    invokecloseTextAreaMicChageEvent = new EventEmitter();
    invokechangeToCaptureModeTextAreaEvent = new EventEmitter();
    invokeemitActiveBoardChanged = new EventEmitter();
    // invokeTabClick = new EventEmitter();
    invokeUnReadGlobal = new EventEmitter();
    //tanju - 12.06.2021
    invokeOnValueChageEvent = new EventEmitter();
    invokePdfCreateEvent = new EventEmitter();
    invokeCloseEditPageEvent = new EventEmitter();
    //Used on signature to change color of box when its required but value is empty
    invokeWarningChangeEvent = new EventEmitter();
    invokeJobListEvent = new EventEmitter();
    invokeProjectManagementEvent = new EventEmitter();
    invokeProfileManagementEvent = new EventEmitter();
    invokeNotificationManagementEvent = new EventEmitter();
    invokescrollChatMessageEvent = new EventEmitter();
    invokeIsSeenMessageEvent = new EventEmitter();
    invokeOpenFormComponentInPopupFunction = new EventEmitter();
    invokeCloseSelectBoxContentEvent = new EventEmitter();
    invoketextBoxChangeEventEvent = new EventEmitter();
    invokesetSidebarCollapsibleEvent = new EventEmitter();
    invokeRequiredFieldAnimationEvent = new EventEmitter();
    invokeChangeEventSizeUpdate = new EventEmitter();
    invokeDatalistLoadVisible = new EventEmitter();
    invokeHideJobListTabs = new EventEmitter();
    invokeShowUserPhotosEvent = new EventEmitter();
    invokeSearchInputEvent = new EventEmitter();
    subsSendForm: Subscription;
    subsFormChange: Subscription;
    subsFormElementChange: Subscription;
    subsSubmit: Subscription;
    subsLayoutChange: Subscription;
    subsMenuSubmit: Subscription;
    subsStartInstance: Subscription;

    deleteFileIdsArray: any[] = []
    invokeYesClick = new EventEmitter();
    config: any;


    constructor() { }

    applyHistory(act) {
        this.invokeHistoryFunction.emit({ action: act });
    }

    executeExpretion(anotherPopupIdLocal) {
        this.invokeFormChangesFunction.emit({ anotherPopupIdLocal: anotherPopupIdLocal });
    }

    sendForm(button, reason, anotherPopupIdLocal) {
        this.invokeSendFormFunction.emit({ button: button, reason: reason, anotherPopupIdLocal: anotherPopupIdLocal });
    }

    submitForm() {
        this.invokeSubmitFormFunction.emit();
    }

    updateFavoriteChange(data) {
        this.invokeFavoriteChangeEvent.emit({ data: data });
    }

    processChageEvent(data) {
        this.invokeFavoriteChangeEvent.emit({ data: data });
    }

    pageWithRouteChange(data) {
        this.invokePageWithRouteChangeEvent.emit({ data: data });
    }
    isUserLoginControl(data) {
        this.invokeLogOutFunc.emit({ data: data });
    }

    createTaskAndStartForm(data) {
        this.invokeCreateTaskAndStartFormEvent.emit({ data: data });
    }

    setLanguageFunc(data) {
        this.invokeSetLanguageEvent.emit({ data: data });
    }

    setHomePopupVisibleFunc(data) {
        this.invokeSetHomePopupVisibleEvent.emit({ data: data });
    }

    getProcessInfo(data) {
        this.invokeGetProcessInfo.emit({ data: data });
    }

    refreshGrid(data) {
        this.invokeRefreshGridEvent.emit({ data: data });
    }

    menuChildByHomeEvent(data) {
        this.invokeMenuChildByHomeEvent.emit({ data: data });
    }
    updateHomeChildEvent(data) {
        this.invokeUpdateHomeChildEvent.emit({ data: data });
    }

    homeLoading(data) {
        this.invokeHomeLoadingEvent.emit({ data: data });
    }

    openChildForm(data) {
        this.invokeOpenChildFormChageEvent.emit({ data: data });
    }
    openHistoryChildForm(data) {
        this.invokeOpenHistoryChildFormChageEvent.emit({ data: data });
    }

    layoutChange() {
        this.invokeLayoutChangeFunction.emit();
    }

    showPopop(id, status = false) {
        this.invokeShowPopopFunction.emit({ idStatus: status, key: id });
    }

    schedulerInit(id, isReloadData = false, schedulerEventClickActive = null) {
        this.invokeSchedulerInit.emit({ key: id, isReloadData: isReloadData, schedulerEventClickActive: schedulerEventClickActive });
    }

    ChangeVisible(data) {
        this.invokeChangeVisibleFunction.emit({ data: data });
    }
    changeDisableStatus(data) {
        this.invokeChangeDisabledFunction.emit({ data: data });
    }

    ChangeFilterOutElement(data) {
        //ChangeFilterOutElement element value
        this.invokeChangeFilterOutElement.emit({ data: data });
    }
    setColumnSumToComponent(id, sum) {
        this.invokeColumnSumFunction.emit({ id: id, sum: sum });
    }

    setDeletedFileIds(id) {
        this.deleteFileIdsArray.push(id)
        this.invokeDeletedFileIdsFunction.emit(this.deleteFileIdsArray)
    }

    clearDeleteFileIds() {
        this.deleteFileIdsArray = []
        this.invokeDeletedFileIdsFunction.emit(this.deleteFileIdsArray)
    }
    openFormFromNotify(data: any) {
        this.invokeOpenFormEvent.emit({ data: data })
    }
    submitMenu(button, reason, anotherPopupIdLocal) {
        this.invokeMenuSubmitFunction.emit({ button: button, reason: reason, anotherPopupIdLocal: anotherPopupIdLocal });
    }

    colorSetData(data) {
        this.invokeColorSetDataEvent.emit({ data: data });
    }

    searchSetData(data) {
        this.invokeSearchSetDataEvent.emit({ data: data });
    }

    getAllSchemaList(data) {
        this.invokeGetAllSchemaListEvent.emit({ data: data });
    }

    applySelecboxData(id, data) {
        this.invokeNestedSelecboxFunction.emit({ id: id, data: data });
    }

    selectboxChangeEvent(id, elementId, value, data, isShowComfirm = true, anotherPopupIdLocal = 0, overrideUserChange = false, datagridFillColumn = undefined, schedulerFillPopup = false) {
        this.invokeChangeEvent.emit({ id: id, elementId: elementId, value: value, data: data, isShowComfirm: isShowComfirm, anotherPopupId: anotherPopupIdLocal, overrideUserChange: overrideUserChange, datagridFillColumn: datagridFillColumn, schedulerFillPopup: schedulerFillPopup });
    }

    datagridOpenEditRowPopup(data) {
        this.invokeDatagridOpenEditRowPopupEvent.emit({ data: data });
    }

    buttonClickChangeEvent(data) {
        this.invokeButtonClick.emit({ data: data });
    }

    warningChangeEvent(data) {
        this.invokeWarningChangeEvent.emit({ data: data });
    }

    galleryChangeEvent(id, elementId, data) {
        this.invokeGalleryChangeEvent.emit({ id: id, elementId: elementId, data: data });
    }

    saveSystemInfoEvent(elementId, data) {
        this.invokeSaveSystemInfoEvent.emit({ elementId: elementId, data: data });
    }

    menuTabComponentChange(data) {
        this.invokeChangeMenuTabComponentEvent.emit({ data: data });
    }

    openForm(data) {
        this.invokeStartInstanceFunction.emit({ data: data });
    }

    openMenu(data) {
        this.invokeStartMenuFunction.emit({ data: data });
    }

    taskLinkFrontPanel(data) {
        this.invokeTaskLinkFrontPanelFunction.emit({ data: data });
    }

    getAllComponent(component) {
        this.invokeGetAllComponentFunction.emit({ component: component });
    }

    updateTasks(anotherPopupIdLocal = 0) {
        this.invokeUpdateTasksFuntion.emit({ anotherPopupIdLocal: anotherPopupIdLocal });
    }

    onValueChangeEvent(id, elementId, value, data) {
        this.invokeOnValueChageEvent.emit({ id: id, elementId: elementId, value: value, data: data });
    }
    onValueChangeEventForReport(id, elementId, value, data) {
        this.invokeOnValueChageEventForReport.emit({ id: id, elementId: elementId, value: value, data: data });
    }
    pdfCreateEvent(button, anotherPopupId, reportId) {
        this.invokePdfCreateEvent.emit({ button: button, anotherPopupId: anotherPopupId, reportId: reportId });
    }

    closeTextAreaMicEvent(data) {
        this.invokecloseTextAreaMicChageEvent.emit({ data: data });
    }

    changeToCaptureModeTextAreaEvent(data) {
        this.invokechangeToCaptureModeTextAreaEvent.emit({ data: data })
    }

    emitActiveBoardChanged(board: string) {

        this.invokeemitActiveBoardChanged.emit({ data: board });
    }

    projectManagementEvent(data: any) {
        this.invokeProjectManagementEvent.emit({ data: data });
    }
    profileManagementEvent(data: any) {
        this.invokeProfileManagementEvent.emit({ data: data });
    }
    notificationManagementEvent(data: any) {
        this.invokeNotificationManagementEvent.emit({ data: data });
    }

    jobListChat(data: any) {
        
        this.invokeJobListEvent.emit({ data: data });
    }
    profileEditPostData(id, isTrue) {
        this.invokePostData.emit({ id: id, isTrue: isTrue });
    }
    showStickyNotes(show: boolean) {
        this.invokeShowStickyEvent.emit({ show: show });
    }

    openSearchPopup(show: boolean) {
        this.invokeOpenSearchPopupEvent.emit({ show: show });
    }

    closeEditPage(close: boolean) {
        this.invokeCloseEditPageEvent.emit({ close: close });
    }
    loginParams(pageId: number) {

        this.invokeLoginParams.emit({ pageId: pageId });
    }

    openProfileSideBar(open: boolean) {

        this.invokeOpenProfileSideBar.emit({ open: open });
    }

    closeSettingsIcon(close: boolean) {
        this.invokeCloseSettingsIcon.emit({ close: close });
    }

    navbarColor(value: boolean) {
        this.invokeNavbarColor.emit({ value: value });
    }
    readMessage(readingMessage: number) {
        this.invokeReadMessage.emit({ readingMessage })
    }
    chatName(chatName: string) {
        this.invokeChatName.emit({ chatName })
    }
    scrollChatMessage(id: number) {
        this.invokescrollChatMessageEvent.emit({ id })
    }
    isSeenMessage(isSeen: boolean) {
        this.invokeIsSeenMessageEvent.emit(isSeen)
    }
    setSelectedChat(id: number) {
        this.invokeSelectedChats.emit(id)
    }
    openFormComponentInPopup(componentId: string) {
        this.invokeOpenFormComponentInPopupFunction.emit({ componentId: componentId })
    }
    formLoad(data: boolean) {
        this.invokeFormLoad.emit({ data });
    }

    invokeCloseSelectBoxContent(data: boolean) {
        this.invokeCloseSelectBoxContentEvent.emit({ data });
    }
    textBoxChangeEvent(data: any) {
        this.invoketextBoxChangeEventEvent.emit({ data });
    }
    setSidebarCollapsible(data: boolean) {
        this.invokesetSidebarCollapsibleEvent.emit( data );
    }

    triggerRequiredFieldAnimation(containerCompUuid,containerCompId,uuid) {
        this.invokeRequiredFieldAnimationEvent.emit({ containerCompUuid: containerCompUuid, containerCompId: containerCompId, uuid: uuid });
    }
    changeEventSizeUpdate(data) {
        this.invokeChangeEventSizeUpdate.emit(data)
    }
    datalistLoadVisible(data: boolean) {
        this.invokeDatalistLoadVisible.emit(data);
    }
    searchInputChangeEvent(data: any) {
        this.invokeSearchInputEvent.emit(data);
    }

    hideJobListTabs(data: boolean) {
        this.invokeHideJobListTabs.emit(data);
    }

    joblistShowUserPhotosData(data: boolean) {
        this.invokeShowUserPhotosEvent.emit(data);
    }
    closeNavbarSearch(data: boolean) {
        this.invokeCloseNavbarSearch.emit(data);
    }

}
